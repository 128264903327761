<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title" style="width: 100%">
                Painel de Equipes em J4
                <a
                  data-toggle="collapse"
                  href="#"
                  @click="getGerenciamentos()"
                  class="btn-filter"
                >
                  Atualizar <i class="fas fa-sync-alt"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#filterCars"
                  aria-expanded="false"
                  aria-controls="filterCars"
                  class="btn-filter"
                  style="padding-right: 25px"
                  v-if="auto_update"
                >
                  Filtros
                  <i class="fas fa-filter"></i>
                </a>
                <!-- <span class="btn-filter mr-3" style="margin-top: -3px">
                  <toggle-button
                    v-model="auto_update"
                    color="#15488A"
                    :height="20"
                    :width="40"
                    @change="autoUpdateToggle"
                  />
                  Atualizar automaticamente
                </span> -->
                <span class="btn-filter mr-3" style="margin-top: -3px">
                  <toggle-button
                    v-model="auto_update"
                    color="#15488A"
                    :height="20"
                    :width="40"
                    @change="toggleExibirTodos"
                  />
                  Exibir todos
                </span>
                <span class="btn-filter mr-3" style="margin-top: -5px">
                  <button
                    class="btn btn-export-j4 float-left btn-sm"
                    data-toggle="modal"
                    data-target="#modalExport"
                  >
                    Exportar em Excel
                  </button>
                </span>
              </div>
            </div>

            <div
              class="modal fade"
              id="modalExport"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalExportTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Exportar
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-content modal-lg mt-1">
                    <div
                      class="alert alert-warning"
                      role="alert"
                      v-if="!filters.data_inicio && !filters.data_fim"
                    >
                      Para exportar todos os registros, basta não definir nenhum
                      intervalo de datas e proceder com a exportação.
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="col-md-6 mb-1">
                        <label>Data de Ínicio</label>
                        <input
                          type="date"
                          class="form-control"
                          v-model="filters.data_inicio"
                        />
                      </div>
                      <div class="col-md-6 mb-1">
                        <label>Data do Fim</label>
                        <input
                          type="date"
                          class="form-control"
                          v-model="filters.data_fim"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="exportToExcel"
                    >
                      Exportar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body car-filters">
              <div class="collapse" id="filterCars">
                <div class="row">
                  <div class="col-md-6">
                    <label>Nome da Equipe</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filters.equipe"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Número da Solicitação</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filters.solicitacao"
                    />
                  </div>
                </div>

                <div class="row mb-3 mt-1">
                  <div class="col-12">
                    <button
                      type="button"
                      @click="getGerenciamentos"
                      class="btn btn-add"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
              <section>
                <table
                  class="table table-striped table-bordered"
                  v-if="auto_update == true"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th>Equipe</th>
                      <th>Veículo</th>
                      <th>Nº Solicitação</th>
                      <th>Data Início</th>
                      <th>Data Fim</th>
                      <th>Tempo que Permaneceram</th>
                      <th>Tipo</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="gerenciamento in gerenciamentos"
                      :key="gerenciamento.gerenciar_j4_id"
                    >
                      <td>{{ gerenciamento.equipe.equipe_nome }}</td>
                      <td>
                        {{
                          gerenciamento.equipe.veiculo
                            ? gerenciamento.equipe.veiculo.nome_veiculo
                            : "N/A"
                        }}
                        -
                        {{
                          gerenciamento.equipe.veiculo
                            ? gerenciamento.equipe.veiculo.placa_veiculo
                            : "N/A"
                        }}
                      </td>
                      <td>
                        {{
                          gerenciamento.solicitacao
                            ? gerenciamento.solicitacao.numero_solicitacao
                            : "-"
                        }}
                      </td>
                      <td>{{ gerenciamento.data_inicio_f }}</td>
                      <td>{{ gerenciamento.data_fim_f || "-" }}</td>
                      <td>
                        {{
                          formatarTempo(
                            gerenciamento.data_inicio,
                            gerenciamento.data_fim
                          )
                        }}
                      </td>
                      <td>
                        {{
                          gerenciamento.tipo === 1
                            ? "Almoço"
                            : gerenciamento.tipo === 2
                            ? "Jantar"
                            : "-"
                        }}
                      </td>
                      <td
                        v-if="
                          $store.state.user.perfil_id != 6 &&
                          $store.state.user.perfil_id != 21 &&
                          $store.state.user.perfil_id != 11
                        "
                      >
                        <button
                          v-if="!gerenciamento.data_fim"
                          class="btn btn-j4 float-left btn-sm"
                          @click="encerrarJ4(gerenciamento.gerenciar_j4_id)"
                        >
                          <i class="fas fa-cog"></i> Encerrar J4
                        </button>
                        <span v-else>J4 Encerrado</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  class="table table-striped table-bordered"
                  v-if="auto_update == false"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th>Equipe</th>
                      <th>Veículo</th>
                      <th>Nº Solicitação</th>
                      <th>Data Início</th>
                      <th>Data Fim</th>
                      <th>Tempo que Permaneceram</th>
                      <th>Tipo</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in combinedData"
                      :key="item.equipe_id"
                      :class="{ 'text-danger': !item.gerenciamentos }"
                    >
                      <td>{{ item.equipe_nome }}</td>
                      <td v-if="item.gerenciamentos">
                        <div
                          v-for="(gerenciamento, gIndex) in item.gerenciamentos"
                          :key="'veiculo-' + gerenciamento.id"
                        >
                          {{ gerenciamento.equipe.veiculo.nome_veiculo }} -
                          {{ gerenciamento.equipe.veiculo.placa_veiculo }}
                          <div v-if="gIndex < item.gerenciamentos.length - 1">
                            <hr />
                          </div>
                        </div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="item.gerenciamentos">
                        <div
                          v-for="(gerenciamento, gIndex) in item.gerenciamentos"
                          :key="'solicitacao-' + gerenciamento.id"
                        >
                          {{
                            gerenciamento.solicitacao
                              ? gerenciamento.solicitacao.numero_solicitacao
                              : "-"
                          }}
                          <div v-if="gIndex < item.gerenciamentos.length - 1">
                            <hr />
                          </div>
                        </div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="item.gerenciamentos">
                        <div
                          v-for="(gerenciamento, gIndex) in item.gerenciamentos"
                          :key="'inicio-' + gerenciamento.id"
                        >
                          {{ gerenciamento.data_inicio_f }}
                          <div v-if="gIndex < item.gerenciamentos.length - 1">
                            <hr />
                          </div>
                        </div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="item.gerenciamentos">
                        <div
                          v-for="(gerenciamento, gIndex) in item.gerenciamentos"
                          :key="'fim-' + gerenciamento.id"
                        >
                          {{ gerenciamento.data_fim_f || "-" }}
                          <div v-if="gIndex < item.gerenciamentos.length - 1">
                            <hr />
                          </div>
                        </div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="item.gerenciamentos">
                        <div
                          v-for="(gerenciamento, gIndex) in item.gerenciamentos"
                          :key="'tempo-' + gerenciamento.id"
                        >
                          {{
                            formatarTempo(
                              gerenciamento.data_inicio,
                              gerenciamento.data_fim
                            )
                          }}
                          <div v-if="gIndex < item.gerenciamentos.length - 1">
                            <hr />
                          </div>
                        </div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="item.gerenciamentos">
                        <div
                          v-for="(gerenciamento, gIndex) in item.gerenciamentos"
                          :key="'tipo-' + gerenciamento.id"
                        >
                          {{
                            gerenciamento.tipo === 1
                              ? "Almoço"
                              : gerenciamento.tipo === 2
                              ? "Jantar"
                              : "-"
                          }}
                          <div v-if="gIndex < item.gerenciamentos.length - 1">
                            <hr />
                          </div>
                        </div>
                      </td>
                      <td v-else>-</td>
                      <td
                        v-if="
                          item.gerenciamentos &&
                          $store.state.user.perfil_id != 6 &&
                          $store.state.user.perfil_id != 21 &&
                          $store.state.user.perfil_id != 11
                        "
                      >
                        <div
                          v-for="(gerenciamento, gIndex) in item.gerenciamentos"
                          :key="'acoes-' + gerenciamento.id"
                        >
                          <button
                            v-if="gerenciamento.data_fim == null"
                            class="btn btn-j4 float-left btn-sm"
                            @click="encerrarJ4(gerenciamento.id)"
                          >
                            <i class="fas fa-cog"></i> Encerrar J4
                          </button>
                          <span v-else>J4 Encerrado</span>
                          <div v-if="gIndex < item.gerenciamentos.length - 1">
                            <hr />
                          </div>
                        </div>
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>

                <div
                  class="col-md-6 mt-1 mx-auto d-flex justify-content-center align-items-center"
                >
                  <pagination
                    :records="total"
                    :options="options"
                    :per-page="15"
                    @paginate="getGerenciamentos"
                    v-show="auto_update"
                  ></pagination>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";
import InfiniteLoading from "vue-infinite-loading";
import { Pagination } from "vue-pagination-2";

export default {
  data() {
    return {
      filters: {
        solicitacao: null,
        equipe: null,
      },
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      pages: [],
      gerenciamentos: [],
      auto_update: false,
      count: {
        loaded: false,
      },
      updating: false,
      searchTerm: "",
      searchTermNaoAtribuidos: "",
      searchTermAtribuidos: "",
      page: 1,
      section: 1,
      equipe_id: null,
      selectedMenu: "todos",
      searchTerm: "",
      searchTermAtribuidos: "",
      moment: moment,
      atendimentos: [],
      regioes: [],
      equipes: [],
      equipes_embarque: [],
      tipos_profissionais: [],
      veiculos: [],
      posicoes: [],
      todas_posicoes: [],
      tipos_veiculo: [],
      equipe_embarque: {
        equipe_nome: "",
        atribuidos: [],
        nao_atribuidos: [],
      },
      veiculo_selecionado: {
        placa_veiculo: "",
      },
      modal_solicitacao: {},
      filtro_profissional: "",
      loadedEmbarque: false,
      data_equipe: {},
      data_rastreamento: {},
      center: { lat: 0, lng: 0 },
      markerPosition: { lat: 0, lng: 0 },
      interval: null,
      auto_update: false,
      exibir_todos: false,
      veiculos_enviados: [],
      combinedData: [],
    };
  },
  computed: {},
  methods: {
    exportToExcel() {
      const self = this;
      const dataInicio = self.filters.data_inicio;
      const dataFim = self.filters.data_fim;

      if (dataInicio || dataFim) {
        if (dataInicio && dataFim) {
          var url = `${self.$store.state.url}js4/exportar?data_inicio=${dataInicio}&data_fim=${dataFim} 23:59:59`;
          window.open(url, "_blank").focus();
        } else {
          self.$message(
            null,
            "Por favor, preencha ambos os campos de data.",
            "error"
          );
          return;
        }
      } else {
        var url = `${self.$store.state.url}js4/exportar`;
        window.open(url, "_blank").focus();
      }
    },
    formatarTempo(dataInicio, dataFim) {
      if (!dataInicio || !dataFim) {
        return "-";
      }

      const diff = Math.abs(new Date(dataFim) - new Date(dataInicio));
      const minutos = Math.floor(diff / 60000);

      const horas = Math.floor(minutos / 60);
      const minutosRestantes = minutos % 60;

      if (horas === 0) {
        return `${minutosRestantes} minuto${minutosRestantes !== 1 ? "s" : ""}`;
      } else {
        return `${horas} hora${
          horas !== 1 ? "s" : ""
        } e ${minutosRestantes} minuto${minutosRestantes !== 1 ? "s" : ""}`;
      }
    },
    encerrarJ4(gerenciamentoId) {
      const self = this;
      let api = self.$store.state.api + "encerrar-j4";

      swal({
        title: "Deseja encerrar o J4 para esta equipe?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, encerrar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal(
              "Cancelado",
              "O encerramento do J4 não foi realizado!",
              "info"
            );
            break;

          case "confirm":
            const gerenciamento = self.gerenciamentos.find(
              (g) => g.gerenciar_j4_id === gerenciamentoId
            );

            if (gerenciamento) {
              const dataToSend = {
                gerenciar_j4_id: gerenciamento.gerenciar_j4_id,
              };

              if (
                gerenciamento.solicitacao &&
                gerenciamento.solicitacao.transporte_sanitario_id
              ) {
                self
                  .getEnvioVeiculo(
                    gerenciamento.solicitacao.transporte_sanitario_id
                  )
                  .then((envioVeiculo) => {
                    dataToSend.envio_veiculo = envioVeiculo;

                    axios
                      .post(api, dataToSend)
                      .then((response) => {
                        swal(
                          "Sucesso!",
                          "O J4 foi encerrado com sucesso!",
                          "success"
                        );
                        self.getGerenciamentos();
                        self.getEnvioVeiculo(
                          gerenciamento.solicitacao.transporte_sanitario_id
                        );
                      })
                      .catch((error) => {
                        self.$message(null, error.response.data, "error");
                      });
                  })
                  .catch((error) => {
                    self.$message(null, error.response.data, "error");
                  });
              } else {
                axios
                  .post(api, dataToSend)
                  .then((response) => {
                    swal(
                      "Sucesso!",
                      "O J4 foi encerrado com sucesso!",
                      "success"
                    );
                    self.getGerenciamentos();
                  })
                  .catch((error) => {
                    self.$message(null, error.response.data, "error");
                  });
              }
            } else {
              self.$message(null, "Gerenciamento não encontrado!", "error");
            }
            break;
        }
      });
    },

    alterarSituacao() {
      const self = this;
      $("#modalEquipeCenter").modal("toggle");
      $("#modalManutencaoFrota").modal("toggle");

      self.veiculo_selecionado = self.data_equipe.veiculo;
    },
    ajustarEmbarque() {
      const self = this;
      $("#modalEquipeCenter").modal("toggle");
      $("#modalEmbarqueEquipe").modal("toggle");

      self.equipe_id = self.data_equipe;

      self.resetEquipe();
    },
    visualizarOcorrencia() {
      const self = this;
      $("#modalEquipeCenter").modal("toggle");
      self.$router.push(
        "programacoes/programacao/" +
          self.data_equipe.tih.transporte_sanitario_id
      );
    },
    getRastreamentoChronos() {
      const self = this;
      let api = self.$store.state.api + "veiculo/rastreamento";

      self.$loading(true);
      axios
        .post(api, {
          placa: self.data_equipe.veiculo.placa_veiculo.replace(/[\s-]/g, ""),
        })
        .then((response) => {
          self.data_rastreamento = response.data;
          self.section = 2;
          self.center = {
            lat: parseFloat(self.data_rastreamento.latitude),
            lng: parseFloat(self.data_rastreamento.longitude),
          };
          self.markerPosition = {
            lat: parseFloat(self.data_rastreamento.latitude),
            lng: parseFloat(self.data_rastreamento.longitude),
          };
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(
            null,
            "Falha ao recuperar os dados do rastreador",
            "error"
          );
        });
    },
    getEquipe(id) {
      const self = this;
      let api = self.$store.state.api + "equipe";
      self.$loading(true);

      axios
        .post(api, { equipe_id: id })
        .then((response) => {
          self.data_equipe = response.data;
          self.$loading(false);

          if (!self.updating) {
            setTimeout(() => {
              $("#modalEquipeCenter").modal("toggle");
            }, 400);
          }
          self.updating = false;
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, "Falha ao recuperar os dados da equipe", "error");
        });
    },
    getGerencia: function () {
      const self = this;

      self.$loading(true);

      let api = self.$store.state.api + "dashboard-gerencia";

      axios
        .post(api)
        .then((response) => {
          self.equipes = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    toggleExibirTodos: function (value) {
      this.auto_update = value.value;
      this.getGerenciamentos();
    },

    getGerenciamentos: function (page) {
      const self = this;

      self.page = page || 1;

      let api = self.$store.state.api + "js4?page=" + self.page;

      self.$loading(true);

      if (self.auto_update) {
        api =
          self.$store.state.api +
          "exibir_todos_j4?&orderByDesc&page=" +
          self.page;
        self.$loading(true);
      }

      axios
        .get(api, {
          params: {
            page: self.page,
            solicitacao: self.filters.solicitacao,
            equipe: self.filters.equipe,
          },
        })
        .then((response) => {
          if (self.auto_update) {
            self.gerenciamentos = response.data.data;
            self.total = response.data.total;
          } else {
            self.gerenciamentos = response.data.gerenciamentos.data;
            self.total = response.data.gerenciamentos.total;
            self.equipes = response.data.equipes;
          }

          // Combina as equipes com os gerenciamentos
          self.combinedData = self.equipes.map((equipe) => {
            let gerenciamentos = self.gerenciamentos.filter(
              (g) => g.equipe_id === equipe.equipe_id
            );
            return {
              ...equipe,
              gerenciamentos: gerenciamentos.length > 0 ? gerenciamentos : null,
            };
          });

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getGerenciaEmbarque: function () {
      const self = this;
      let api = self.$store.state.api + "equipes?paginated=false&status=1";

      self.equipes = [];

      axios
        .get(api)
        .then((response) => {
          self.equipes_embarque = response.data.data;

          // self.getEmbarqueEquipe();
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getPosicoes: function () {
      const self = this;
      let api =
        self.$store.state.api +
        "posicao_veiculo?paginated=false&exibir_kanban=1&orderByAsc=ordem";

      axios
        .get(api)
        .then((response) => {
          self.posicoes = response.data.data;
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getTodasPosicoes: function () {
      const self = this;
      let api = self.$store.state.api + "posicao_veiculo?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.todas_posicoes = response.data.data;
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getRegioes: function () {
      const self = this;
      let api = self.$store.state.api + "regioes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.regioes = response.data.data;
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getEmbarqueEquipe: function ($state) {
      const self = this;

      if (self.equipe_id == null) {
        self.$loading(false);
        return;
      }

      // self.loadedEmbarque = false;
      let api = self.$store.state.api + "embarcar_equipe/get?page=" + self.page;
      let timeOut = 0;

      if (this.page > 1) {
        timeOut = 1000;
      }

      setTimeout(() => {
        axios
          .post(api, {
            equipe_id: self.equipe_id,
            tipo_profissional_id: self.filtro_profissional,
            nome_profissional_naoatribuidos: self.searchTermNaoAtribuidos,
          })
          .then((response) => {
            var embarque = response.data;
            const nao_atribuidos = [];

            self.lastPage = embarque.nao_atribuidos.last_page;
            self.equipe_embarque.equipe_id = embarque.equipe_id;
            self.equipe_embarque.equipe_nome = embarque.equipe_nome;

            self.equipe_embarque.atribuidos = embarque.atribuidos;

            if (!self.equipe_embarque.nao_atribuidos) {
              self.equipe_embarque.nao_atribuidos = [];
            } else {
              self.equipe_embarque.nao_atribuidos.forEach((element) => {
                nao_atribuidos.push(element);
              });
            }

            embarque.nao_atribuidos.data.forEach((data, index) => {
              nao_atribuidos.push(JSON.parse(JSON.stringify(data)));
            });

            self.equipe_embarque.nao_atribuidos = nao_atribuidos;

            self.loadedEmbarque = true;

            if ($state) {
              if (self.page >= self.lastPage) {
                $state.complete();
              } else {
                $state.loaded();
              }
            }

            self.$loading(false);
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
            self.$loading(false);
          });

        this.page = this.page + 1;
      }, timeOut);
    },
    getTipoProfissional: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_profissional?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    nao_atribuidos_debounce() {
      const self = this;
      self.page = 1;

      clearTimeout(self.debounceTimeout);
      self.debounceTimeout = setTimeout(() => {
        self.equipe_embarque.nao_atribuidos = [];
        self.equipe_embarque.atribuidos = [];
        self.$loading(true);
        self.getEmbarqueEquipe();
      }, 800); // Tempo de debounce (300 milissegundos)
    },
    salvarEquipe() {
      const self = this;
      let api = self.$store.state.api + "embarcar_equipe/save";

      axios
        .post(api, self.equipe_embarque)
        .then((response) => {
          self.$message(
            "Sucesso",
            "Embarque da equipe, salvo com sucesso!",
            "success"
          );
          self.getGerencia();
        })
        .catch((error) => {
          self.resetEquipe();
          self.$message(null, error.response.data, "error");
        });
    },
    resetEquipe: function () {
      const self = this;
      self.page = 1;
      self.equipe_embarque.nao_atribuidos = [];
      self.equipe_embarque.atribuidos = [];
      self.$loading(true);
      self.getEmbarqueEquipe();
    },
    insere(index, obj) {
      const self = this;

      self.equipe_embarque.nao_atribuidos.splice(index, 1);
      self.equipe_embarque.atribuidos.push(obj);

      self.searchTermAtribuidos = "Carregando...";
      self.searchTerm = "Carregando...";
      setTimeout(function () {
        self.searchTermAtribuidos = "";
        self.searchTerm = "";
      }, 600);

      self.salvarEquipe();
    },
    remove(index, obj) {
      const self = this;

      self.equipe_embarque.atribuidos.splice(index, 1);
      self.equipe_embarque.nao_atribuidos.push(obj);

      self.searchTermAtribuidos = "Carregando...";
      self.searchTerm = "Carregando...";
      setTimeout(function () {
        self.searchTermAtribuidos = "";
        self.searchTerm = "";
      }, 600);

      self.salvarEquipe();
    },
    setPosition(obj, position) {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/movimentar_veiculo";

      if (position.hora_posicao != "-") {
        swal(
          "Cancelado",
          "Já existe uma movimentação gravada para esta posição",
          "info"
        );
        return;
      }

      swal({
        title: "Deseja gravar a data e hora desta posição?",
        text: "O horário da movimentação será o horário atual.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi alterado!", "info");
            break;

          case "confirm":
            self.$loading(true);

            const today = moment().format("YYYY-MM-DD HH:mm:ss");

            var data = {
              veiculo: {
                envio_veiculo_id: obj.veiculo.envio_veiculo_id,
                veiculo_id: obj.veiculo.veiculo_id,
              },
              tipo: position.posicao.posicao_veiculo_id,
              data: today,
            };

            axios
              .post(api, data)
              .then((response) => {
                self.$loading(false);
                swal("OK!", "O horário foi gravado com sucesso", "success");
                self.updating = true;
                self.getEquipe(self.data_equipe.equipe_id);
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
                self.$loading(false);
              });
            break;
        }
      });
    },

    getEnvioVeiculo: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "envio_veiculo?transporte_sanitario_id=" +
        id +
        "&with[]=veiculo&with[]=posicoes&with[]=usuario_delecao";
      return axios
        .get(api)
        .then((response) => {
          self.veiculos_enviados = response.data.data;
          self.isLoading = false;
          return response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.isLoading = false;
        });
    },
    salvarVeiculo: function () {
      const self = this;
      let api = self.$store.state.api + "veiculo";

      const today = moment().format("YYYY-MM-DD HH:mm:ss");

      self.veiculo_selecionado.ultima_atualizacao_posicao = today;

      if (self.veiculo_selecionado.veiculo_id) {
        self.veiculo_selecionado._method = "PUT";
        api += "/" + self.veiculo_selecionado.veiculo_id;
      }

      axios
        .post(api, self.veiculo_selecionado)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getGerencia();
          $("#modalManutencaoFrota").modal("hide");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    autoUpdateToggle(event) {
      const self = this;

      setTimeout(() => {
        if (event.value == true) {
          self.interval = setInterval(() => {
            self.getGerenciamentos();
          }, 15000);
        } else {
          clearInterval(self.interval);
        }
      }, 2000);
    },
  },
  mounted: function () {
    const self = this;

    self.getGerencia();
    // self.getGerenciaEmbarque();
    // self.getPosicoes();
    // self.getTipoProfissional();
    // self.getTodasPosicoes();
    // self.getRegioes();
    self.getGerenciamentos();
  },
  beforeDestroy() {
    const self = this;
    self.auto_update = false;
    clearInterval(self.interval);
  },
  components: {
    vSelect,
    InfiniteLoading,
    Pagination,
  },
};
</script>
<style scoped>
/* .card {
  margin-bottom: 1.5rem;
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: 0px 2px 4px #999;
}
.card-header-patio {
  padding: 5px 10px;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 4px solid #28a745;
} */
.button-tripulante {
  border: solid #bebebe 1px;
  border-radius: 3px;
  padding: 3px;
  cursor: pointer;
  width: 75% !important;
}
.block {
  background-color: white;
  border-radius: 3px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  color: black;
}
.block-title {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
}
.block-title-active {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
  animation: animate 1.5s linear infinite;
}
.border-block {
  border: 1px #15488a solid;
  border-radius: 3px;
  margin: 7px;
}
@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.modal-xl {
  max-width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
}
</style>