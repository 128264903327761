<template>
  <div>
    <div class="main-content">
      <!-- Contadores -->
      <!-- <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-md-center" style="font-size: 6px">
            <div class="col-lg">
              <div class="card">
                <div class="card-header-patio">
                  <div class="card-title">Em J11</div>
                </div>
                <div class="card-body">
                  <div v-if="count.loaded">
                    <div class="card-value">s</div>
                  </div>
                  <div v-else>
                    <div class="fa-3x text-center">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg">
              <div class="card">
                <div class="card-header-patio">
                  <div class="card-title">Em J12</div>
                </div>
                <div class="card-body">
                  <div v-if="count.loaded">
                    <div class="card-value">s</div>
                  </div>
                  <div v-else>
                    <div class="fa-3x text-center">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg">
              <div class="card">
                <div class="card-header-patio">
                  <div class="card-title">Em J4</div>
                </div>
                <div class="card-body">
                  <div v-if="count.loaded">
                    <div class="card-value">s</div>
                  </div>
                  <div v-else>
                    <div class="fa-3x text-center">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Aqui começa a listagem de equipes -->

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title" style="width: 100%">
                Painel de Equipes
                <a
                  data-toggle="collapse"
                  href="#"
                  @click="getEquipes()"
                  class="btn-filter"
                >
                  Atualizar
                  <i class="fas fa-sync-alt"></i>
                </a>

                <a
                  data-toggle="collapse"
                  href="#filterCars"
                  aria-expanded="false"
                  aria-controls="filterCars"
                  class="btn-filter"
                  style="padding-right: 25px"
                >
                  Filtros
                  <i class="fas fa-filter"></i>
                </a>
                <!-- <span class="btn-filter mr-3" style="margin-top: -3px">
                  <toggle-button
                    v-model="auto_update"
                    color="#15488A"
                    :height="20"
                    :width="40"
                    @change="autoUpdateToggle"
                  />
                  Atualizar automaticamente
                </span> -->
                <span class="span-filter" style="padding-left: 30%"></span>
              </div>
            </div>
            <div class="card-body car-filters">
              <div class="collapse" id="filterCars">
                <div class="row">
                  <div class="col-md-4">
                    <label>Nome da Equipe</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filters.equipe"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Número da Solicitação</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filters.solicitacao"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Região</label>
                    <v-select
                      :options="regioes"
                      :reduce="(regioes) => regioes.regiao_id"
                      :label="'nome'"
                      v-model="filters.regiao_id"
                      class="vselect"
                    />
                  </div>
                </div>

                <div class="row mb-3 mt-1">
                  <div class="col-12">
                    <button
                      type="button"
                      @click="getEquipes"
                      class="btn btn-add"
                    >
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
              <section>
                <div class="filter-control">
                  <ul class="car-filter controls">
                    <li
                      class="control"
                      data-mixitup-control
                      data-filter="all"
                      :class="{
                        'mixitup-control-active': selectedMenu == 'todos',
                      }"
                      @click="(selectedMenu = 'todos'), getEquipes()"
                    >
                      Todos
                    </li>
                    <li
                      class="control"
                      data-mixitup-control
                      data-filter="all"
                      :class="{
                        'mixitup-control-active':
                          selectedMenu == posicao.posicao_veiculo_id,
                      }"
                      @click="
                        (selectedMenu = posicao.posicao_veiculo_id),
                          getEquipes()
                      "
                      v-for="posicao in posicoes"
                      :key="posicao.posicao_veiculo_id"
                    >
                      {{ posicao.posicao_veiculo_nome }}
                    </li>
                    <li
                      class="control"
                      data-mixitup-control
                      data-filter="inoperante"
                      :class="{
                        'mixitup-control-active': selectedMenu == 'inoperante',
                      }"
                      @click="(selectedMenu = 'inoperante'), getEquipes()"
                    >
                      Inoperância
                    </li>
                  </ul>
                </div>
              </section>

              <section>
                <!-- Cada Veículo/Equipe -->
                <div>
                  <div class="row">
                    <div
                      :key="equipe.id"
                      v-for="equipe in equipes"
                      class="col-xl"
                      style="
                        min-width: 260px !important;
                        max-width: 260px !important;
                      "
                      @click="getEquipe(equipe.id)"
                    >
                      <div class="patio">
                        <a>
                          <div class="card-car">
                            <div
                              class="badge-car"
                              :style="
                                'border-bottom-color: ' +
                                (equipe.veiculo.posicao
                                  ? equipe.veiculo.posicao.color
                                  : 'transparent') +
                                ' !important'
                              "
                            ></div>
                            <img
                              class="img-car2"
                              :src="equipe.veiculo.foto"
                              width="230px"
                            />
                            <div class="equipe-title mt-1">
                              {{ equipe.veiculo.nome_veiculo }}
                            </div>
                            <div class="car-plate1 mt-1">
                              Placa:
                              <span>{{ equipe.veiculo.placa_veiculo }}</span>
                            </div>
                            <div class="car-plate1 mt-1">
                              Tempo na Situação:
                              <span
                                >{{
                                  equipe.veiculo.tempo_ultima_posicao
                                }}
                                min</span
                              >
                            </div>

                            <div
                              class="car-plate mt-1"
                              style="
                                text-align: center;
                                font-weight: bold;
                                color: #555;
                              "
                            >
                              {{
                                equipe.veiculo.posicao
                                  ? equipe.veiculo.posicao.posicao_veiculo_nome
                                  : ""
                              }}
                              <br />
                              {{ equipe.veiculo.ultima_atualizacao_posicao }}
                            </div>
                            <div class="dados_empenho mt-1">
                              <div class="tih-title centered" v-if="equipe.tih">
                                {{ equipe.tih.numero_solicitacao }}
                              </div>
                              <div
                                class="paciente-title centered"
                                v-if="equipe.tih"
                              >
                                {{
                                  equipe.tih.paciente
                                    ? equipe.tih.paciente.nome_paciente
                                    : ""
                                }}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="equipes.length == 0">
                    <div
                      class="col-md-12 centered mt-3"
                      style="font-size: 16px"
                    >
                      Nenhuma equipe nesta posição.
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Equipe -->
    <div
      class="modal fade"
      id="modalEquipeCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEquipeCenterTitle"
      aria-hidden="true"
      v-if="data_equipe.veiculo"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEquipeLongTitle">
              Visualização da Equipe
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
              @click="(data_equipe = {}), (section = 1)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-5">
                <img class="car-pic" :src="data_equipe.veiculo.foto" alt="" />
              </div>
              <div class="col-md-7">
                <div class="car-info">
                  <div class="car-desc">
                    <div class="text">{{ data_equipe.equipe_nome }}</div>
                    <div class="data">
                      {{ data_equipe.veiculo.placa_veiculo }}
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="car-desc">
                    <div class="text">Última Posição:</div>
                    <div class="data">
                      {{ data_equipe.veiculo.posicao.posicao_veiculo_nome }}
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="car-desc">
                    <div class="text">Tempo nesta Posição:</div>
                    <div class="data">
                      {{ data_equipe.veiculo.tempo_ultima_posicao }} minuto(s)
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="car-desc">
                    <div class="text">Data da Última Posição</div>
                    <div class="data">
                      {{ data_equipe.veiculo.ultima_atualizacao_posicao }}
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div
                    class="car-desc"
                    v-if="
                      data_equipe.gerenciamentos &&
                      data_equipe.gerenciamentos.length &&
                      data_equipe.veiculo.posicao.posicao_veiculo_id !== 6 &&
                      data_equipe.veiculo.posicao.posicao_veiculo_id !== 7
                    "
                  >
                    <div class="text">Está em J4?</div>
                    <div class="data">
                      {{ estaEmJ4 }}
                    </div>
                    <div class="clearfix"></div>
                  </div>

                  <div
                    class="car-desc"
                    v-if="
                      estaEmJ4 === 'Não' &&
                      data_equipe.gerenciamentos &&
                      data_equipe.gerenciamentos.length &&
                      data_equipe.veiculo.posicao.posicao_veiculo_id !== 6 &&
                      data_equipe.veiculo.posicao.posicao_veiculo_id !== 7
                    "
                  >
                    <div class="text">Tempo permanecido no J4</div>
                    <div class="data">
                      {{ calcularTempoPermanecidoNoJ4() }}
                    </div>
                    <div class="clearfix"></div>
                  </div>

                  <div class="buttons">
                    <button
                      class="btn btn-del float-left btn-sm"
                      @click="getRastreamentoChronos"
                    >
                      <i class="fas fa-map-marker-alt mr-1"></i>Rastrear Veículo
                    </button>

                    <button
                      v-if="
                        data_equipe.veiculo.posicao.posicao_veiculo_id != 15 &&
                        (!data_equipe.gerenciamentos ||
                          data_equipe.gerenciamentos.length === 0 ||
                          data_equipe.veiculo.posicao.posicao_veiculo_id !=
                            27 ||
                          data_equipe.gerenciamentos[
                            data_equipe.gerenciamentos.length - 1
                          ].data_finalizacao !== null)
                      "
                      class="btn btn-j4 float-left btn-sm ml-1"
                      @click="gerenciarJ4(data_equipe.id)"
                    >
                      <i class="fas fa-cog mr-1"></i> J4
                    </button>

                    <button
                      class="btn btn-blue float-right btn-sm"
                      v-if="data_equipe.tih != null"
                      @click="visualizarOcorrencia"
                    >
                      <i class="fas fa-eye mr-1"></i>Visualizar Ocorrência
                    </button>
                    <button
                      class="btn btn-blue float-right btn-sm mr-2"
                      @click="ajustarEmbarque"
                    >
                      <i class="fas fa-users-cog mr-1"></i>Ajustar Embarque
                    </button>
                    <button
                      class="btn btn-blue float-right btn-sm mr-1"
                      @click="alterarSituacao"
                    >
                      <i class="fas fa-cog mr-1"></i>Alterar Situação
                    </button>
                  </div>

                  <div class="clearfix"></div>
                  <div class="last-trips mt-2" v-if="data_equipe.tih != null">
                    <div class="title mt-2 mb-2">Ocorrência em Andamento:</div>
                    <div class="car-desc">
                      <div class="text">Número da Ocorrência</div>
                      <div class="data">
                        {{ data_equipe.tih.numero_solicitacao }}
                      </div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="car-desc" v-if="data_equipe.tih.paciente">
                      <div class="text">Nome do Paciente</div>
                      <div class="data">
                        {{
                          data_equipe.tih.paciente
                            ? data_equipe.tih.paciente.nome_paciente
                            : ""
                        }}
                      </div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="car-desc" v-if="data_equipe.tih.paciente">
                      <div class="text">Idade do Paciente</div>
                      <div class="data">
                        {{ data_equipe.tih.paciente.idade }}
                      </div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="car-desc">
                      <div class="text">Tipo da Viatura Solicitada</div>
                      <div class="data">
                        {{
                          data_equipe.tih.tipo_transporte.tipo_transporte_nome
                        }}
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section v-if="section == 1">
              <div class="last-trips mt-2" v-if="data_equipe.tih != null">
                <div class="title">Posições:</div>
              </div>
              <div class="row" v-if="data_equipe.tih != null">
                <div
                  class="col-md border-block clickable"
                  v-for="posicao in data_equipe.tih.veiculo.posicoes_all"
                  :key="posicao.posicao_veiculo_id"
                  @click="setPosition(data_equipe.tih, posicao)"
                  v-show="posicao.posicao.posicao_veiculo_id !== 15"
                >
                  <div
                    class="row"
                    :class="
                      posicao.nesta_posicao
                        ? 'block-title-active'
                        : 'block-title'
                    "
                  >
                    <div class="col-md-12">
                      {{ posicao.posicao.posicao_veiculo_nome }}
                    </div>
                  </div>
                  <div class="row block">
                    <div class="col-md-12">
                      {{ posicao.hora_posicao }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="last-trips mt-2">
                <div class="title">Tripulantes embarcados nesta equipe:</div>
                <div class="table-responsive mt-2">
                  <table class="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 60%">Nome</th>
                        <th scope="col" style="width: 20%">Função</th>
                        <th scope="col" style="width: 20%">Data de Embarque</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="equipe_embarcada in data_equipe.equipe"
                        :key="equipe_embarcada.id"
                      >
                        <td>
                          {{ equipe_embarcada.tripulante.tripulante_nome }}
                        </td>
                        <td>
                          {{
                            equipe_embarcada.tripulante.tipo_profissional !=
                            null
                              ? equipe_embarcada.tripulante.tipo_profissional
                                  .tipo_profissional_nome
                              : "Não informado"
                          }}
                        </td>
                        <td>{{ equipe_embarcada.data_gravacao_f }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <section v-if="section == 2">
              <div class="row mt-1">
                <div class="col-md-1">
                  <button class="btn btn-secondary" @click="section = 1">
                    Voltar
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    class="btn btn-primary"
                    @click="getRastreamentoChronos"
                  >
                    <i class="fas fa-redo-alt mr-1"></i>Atualizar
                  </button>
                </div>
              </div>
              <div class="last-trips mt-2 mb-2">
                <div class="title">Rastreamento em tempo real:</div>
              </div>
              <label
                >Última atualização no Chronos Resgate:
                {{ data_rastreamento.data }}</label
              >
              <GmapMap
                :center="center"
                :zoom="17"
                style="width: 100%; height: 500px"
              >
                <GmapMarker :position="markerPosition" />
              </GmapMap>
            </section>
          </div>
        </div>
        <!-- <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
      </div>
    </div>

    <!-- Modal Embarque de Equipe -->
    <div
      class="modal fade"
      id="modalEmbarqueEquipe"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEmbarqueEquipeTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Embarcar Equipe
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <label>Selecione uma Equipe</label>
                <v-select
                  :options="equipes_embarque"
                  label="equipe_nome"
                  v-model="equipe_id"
                  v-on:input="resetEquipe()"
                >
                  <div slot="no-options">
                    Aguarde, carregando lista de Equipes
                  </div>
                </v-select>
              </div>
              <div class="col-md-6">
                <label>Selecione um tipo de profissional</label>
                <v-select
                  :options="tipos_profissionais"
                  label="tipo_profissional_nome"
                  v-model="filtro_profissional"
                  :reduce="
                    (tipos_profissionais) =>
                      tipos_profissionais.tipo_profissional_id
                  "
                  v-on:input="resetEquipe()"
                >
                  <div slot="no-options">
                    Aguarde, carregando lista de Tipos de Profissionais
                  </div>
                </v-select>
              </div>
            </div>

            <div class="row mt-2" v-if="equipe_id != null">
              <div class="col-md-12 title">Movimente os Tripulantes</div>
            </div>

            <div
              class="row mt-2 centered"
              style="
                border: solid #ebe9f1 1px;
                border-radius: 3px;
                max-height: 60vh;
                overflow-y: scroll;
                overflow-x: hidden;
              "
              v-if="loadedEmbarque && equipe_id != null"
            >
              <div
                class="col-md-5 ml-2 mt-1"
                style="font-size: 16px; font-weight: bold"
              >
                Tripulantes não alocados
              </div>
              <div class="col-md-1 mt-1"></div>
              <div
                class="col-md-5 ml-1 mt-1"
                style="font-size: 16px; font-weight: bold"
              >
                Tripulantes alocados
              </div>

              <!-- Aqui abaixo começa a lista de tripulantes -->

              <div
                class="col-md-5 ml-2"
                style="overflow: auto; max-height: 350px"
              >
                <div class="row">
                  <div class="col-md-12">
                    <input
                      type="text"
                      v-model="searchTermNaoAtribuidos"
                      placeholder="Filtrar tripulantes atribuidos"
                      class="form-control"
                      @input="nao_atribuidos_debounce"
                    />
                  </div>
                  <div
                    class="col-md-12 mt-1"
                    v-for="(
                      n_atribuido, index
                    ) in equipe_embarque.nao_atribuidos"
                    :key="n_atribuido.tripulante_id"
                    :id="'key' + index"
                  >
                    <div class="row">
                      <div
                        class="col-md-12 button-tripulante"
                        @click="insere(index, n_atribuido)"
                      >
                        {{ n_atribuido.tripulante_nome }} -
                        {{
                          n_atribuido.tipo_profissional
                            ? n_atribuido.tipo_profissional
                                .tipo_profissional_nome
                            : ""
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <infinite-loading
                      style="text-align: center"
                      @distance="50"
                      @infinite="getEmbarqueEquipe"
                      v-if="equipe_embarque.nao_atribuidos.length > 0"
                    >
                      <div slot="no-more">Todos já foram exibidos.</div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </div>
                </div>
                <div class="row"></div>
              </div>
              <div
                class="col-md-1 d-flex flex-column justify-content-center align-items-center"
              >
                <i class="fas fa-exchange-alt"></i>
              </div>
              <div
                class="col-md-5 ml-1"
                style="overflow: auto; max-height: 350px"
              >
                <input
                  type="text"
                  v-model="searchTermAtribuidos"
                  placeholder="Filtrar tripulantes atribuidos"
                  class="form-control"
                />
                <div
                  class="row mt-1 mb-1"
                  v-for="(atribuido, index) in equipe_embarque.atribuidos"
                  :key="atribuido.tripulante_id"
                >
                  <div
                    class="col-md-12 button-tripulante"
                    @click="remove(index, atribuido)"
                  >
                    {{ atribuido.tripulante_nome }} -
                    {{
                      atribuido.tipo_profissional
                        ? atribuido.tipo_profissional.tipo_profissional_nome
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="salvarEquipe">
              <i class="fas fa-save"></i> Salvar Alterações
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Manutenção Veículo -->
    <div
      class="modal fade"
      id="modalManutencaoFrota"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalManutencaoFrotaTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Manutenção de Frota
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <label>Selecione um Veículo</label>
                <v-select
                  :options="veiculos"
                  label="nome_veiculo"
                  v-model="veiculo_selecionado"
                >
                  <div slot="no-options">
                    Aguarde, carregando lista de veículos.
                  </div>
                </v-select>
              </div>
            </div>
            <section v-if="veiculo_selecionado.placa_veiculo != ''">
              <div class="row mt-1">
                <div class="col-md-3">
                  <label>Placa</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="veiculo_selecionado.placa_veiculo"
                    disabled
                  />
                </div>
                <div class="col-md-5">
                  <label>Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="veiculo_selecionado.nome_veiculo"
                    disabled
                  />
                </div>
                <div class="col-md-4">
                  <label>Tipo do Veículo</label>
                  <select
                    class="form-control"
                    v-model="veiculo_selecionado.tipo_veiculo_id"
                    disabled
                  >
                    <option
                      v-for="tipo in tipos_veiculo"
                      :key="tipo.tipo_veiculo_id"
                      :value="tipo.tipo_veiculo_id"
                    >
                      {{ tipo.tipo_veiculo_nome }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-md-6">
                  <label>Última situação</label>
                  <input
                    v-if="veiculo_selecionado.posicao"
                    type="text"
                    class="form-control"
                    v-model="veiculo_selecionado.posicao.posicao_veiculo_nome"
                    disabled
                  />
                  <input
                    v-else
                    type="text"
                    class="form-control"
                    value="Não informado"
                    disabled
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-6">
                  <label>Nova situação</label>
                  <select
                    class="form-control"
                    v-model="veiculo_selecionado.posicao_veiculo_id"
                  >
                    <option
                      v-for="posicao in todas_posicoes"
                      :key="posicao.posicao_veiculo_id"
                      :value="posicao.posicao_veiculo_id"
                    >
                      {{ posicao.posicao_veiculo_nome }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-6">
                  <label>Está em J4?</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="estaEmJ4"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="row mt-1" v-if="estaEmJ4 === 'Não'">
                <div class="col-md-6">
                  <label>Tempo permanecido no J4</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="calcularTempoPermanecidoNoJ4()"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-12">
                  <label>Justificativa/Observações</label>

                  <textarea
                    class="form-control"
                    id=""
                    cols="30"
                    rows="10"
                    v-model="veiculo_selecionado.justificativa_posicao"
                  ></textarea>
                </div>
              </div>
            </section>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="salvarVeiculo"
            >
              <i class="fas fa-save"></i> Salvar Alterações
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";
import InfiniteLoading from "vue-infinite-loading";

export default {
  data() {
    return {
      estaEmJ4Value: "",
      tempoPermanecidoNoJ4: "",
      auto_update: false,
      count: {
        loaded: false,
      },
      updating: false,
      searchTerm: "",
      searchTermNaoAtribuidos: "",
      searchTermAtribuidos: "",
      page: 1,
      section: 1,
      equipe_id: null,
      selectedMenu: "todos",
      searchTerm: "",
      searchTermAtribuidos: "",
      moment: moment,
      atendimentos: [],
      regioes: [],
      equipes: [],
      equipes_embarque: [],
      tipos_profissionais: [],
      veiculos: [],
      posicoes: [],
      todas_posicoes: [],
      tipos_veiculo: [],
      equipe_embarque: {
        equipe_nome: "",
        atribuidos: [],
        nao_atribuidos: [],
      },
      veiculo_selecionado: {
        placa_veiculo: "",
      },
      modal_solicitacao: {},
      filters: {
        solicitacao: null,
        equipe: null,
        regiao_id: null,
      },
      filter: 0,
      filtro_profissional: "",
      loadedEmbarque: false,
      data_equipe: {},
      data_rastreamento: {},
      center: { lat: 0, lng: 0 },
      markerPosition: { lat: 0, lng: 0 },
      interval: null,
      veiculos_enviados: [],
    };
  },
  computed: {
    estaEmJ4() {
      if (
        this.data_equipe.gerenciamentos &&
        this.data_equipe.gerenciamentos.length > 0
      ) {
        const ultimoGerenciamento = this.data_equipe.gerenciamentos[0];
        if (ultimoGerenciamento.data_fim === null) {
          return "Sim";
        }
      }
      return "Não";
    },
  },
  methods: {
    calcularTempoPermanecidoNoJ4() {
      if (
        this.estaEmJ4 === "Não" &&
        this.data_equipe.gerenciamentos &&
        this.data_equipe.gerenciamentos.length > 0
      ) {
        const gerenciamentos = this.data_equipe.gerenciamentos.filter(
          (g) => g.data_fim !== null
        );
        let totalTempo = 0;
        gerenciamentos.forEach((gerenciamento) => {
          const dataInicio = new Date(gerenciamento.data_inicio);
          const dataFim = new Date(gerenciamento.data_fim);
          const diff = Math.abs(dataFim - dataInicio);
          totalTempo += diff;
        });
        const mediaTempo = totalTempo / gerenciamentos.length;
        const horas = Math.floor(mediaTempo / (1000 * 60 * 60));
        const minutos = Math.floor(
          (mediaTempo % (1000 * 60 * 60)) / (1000 * 60)
        );

        if (horas === 0 && minutos === 0) {
          return "Menos de 1 minuto";
        } else if (horas === 0) {
          return `${minutos} ${minutos === 1 ? "minuto" : "minutos"}`;
        } else if (minutos === 0) {
          return `${horas} ${horas === 1 ? "hora" : "horas"}`;
        } else {
          return `${horas} ${horas === 1 ? "hora" : "horas"} e ${minutos} ${
            minutos === 1 ? "minuto" : "minutos"
          }`;
        }
      } else {
        return "";
      }
    },
    gerenciarJ4(id) {
      const self = this;
      let api = self.$store.state.api + "gerenciar-j4";

      if (
        !self.data_equipe ||
        !self.data_equipe.tih ||
        !self.data_equipe.tih.transporte_sanitario_id
      ) {
        swal({
          title: "Viatura não embarcada!",
          text: "A Viatura não está embarcada em um evento. Deseja continuar mesmo assim?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim, continuar!",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
        }).then((value) => {
          if (value) {
            iniciarJ4(id);
            d;
          } else {
            swal("Cancelado", "O início do J4 não foi iniciado!", "info");
          }
        });
      } else {
        iniciarJ4(id);
      }

      function iniciarJ4(id) {
        swal({
          title: "Deseja iniciar o J4 desta equipe?",
          text: "Essa operação não pode ser desfeita",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim, iniciar!",
          showLoaderOnConfirm: true,
          buttons: {
            catch: {
              text: "Não",
              value: "cancel",
              className: "btn-danger",
            },
            confirm: {
              text: "Sim",
              value: "confirm",
            },
          },
        }).then((value) => {
          switch (value) {
            case "cancel":
              swal("Cancelado", "O início do J4 não foi iniciado!", "info");
              break;

            case "confirm":
              let data = {
                equipe_id: id,
              };

              if (
                self.data_equipe &&
                self.data_equipe.tih &&
                self.data_equipe.tih.transporte_sanitario_id
              ) {
                self
                  .getEnvioVeiculo(self.data_equipe.tih.transporte_sanitario_id)
                  .then((envioVeiculoId) => {
                    data.envio_veiculo_id = envioVeiculoId;
                    data.transporte_sanitario_id =
                      self.data_equipe.tih.transporte_sanitario_id;

                    enviarDados(api, data);
                  })
                  .catch((error) => {
                    self.$message(null, error.response.data, "error");
                  });
              } else {
                enviarDados(api, data);
              }

              break;
          }
        });
      }

      function enviarDados(api, data) {
        axios
          .post(api, data)
          .then((response) => {
            swal("Sucesso!", "O início do J4 foi iniciado!", "success");
            $("#modalEquipeCenter").modal("hide");
            self.getEquipes();
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      }
    },

    getEnvioVeiculo: function (id) {
      const self = this;

      if (!id) {
        return Promise.resolve(null);
      }

      let api =
        self.$store.state.api +
        "envio_veiculo?transporte_sanitario_id=" +
        id +
        "&with[]=veiculo&with[]=posicoes&with[]=usuario_delecao";

      return axios
        .get(api)
        .then((response) => {
          self.veiculos_enviados = response.data.data;
          self.isLoading = false;
          return response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.isLoading = false;
        });
    },

    alterarSituacao() {
      const self = this;
      $("#modalEquipeCenter").modal("toggle");
      $("#modalManutencaoFrota").modal("toggle");

      self.veiculo_selecionado = self.data_equipe.veiculo;
    },
    ajustarEmbarque() {
      const self = this;
      $("#modalEquipeCenter").modal("toggle");
      $("#modalEmbarqueEquipe").modal("toggle");

      self.equipe_id = self.data_equipe;

      self.resetEquipe();
    },
    visualizarOcorrencia() {
      const self = this;
      $("#modalEquipeCenter").modal("toggle");
      self.$router.push(
        "programacoes/programacao/" +
          self.data_equipe.tih.transporte_sanitario_id
      );
    },
    getRastreamentoChronos() {
      const self = this;
      let api = self.$store.state.api + "veiculo/rastreamento";

      self.$loading(true);
      axios
        .post(api, {
          placa: self.data_equipe.veiculo.placa_veiculo.replace(/[\s-]/g, ""),
        })
        .then((response) => {
          self.data_rastreamento = response.data;
          self.section = 2;
          self.center = {
            lat: parseFloat(self.data_rastreamento.latitude),
            lng: parseFloat(self.data_rastreamento.longitude),
          };
          self.markerPosition = {
            lat: parseFloat(self.data_rastreamento.latitude),
            lng: parseFloat(self.data_rastreamento.longitude),
          };
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(
            null,
            "Falha ao recuperar os dados do rastreador",
            "error"
          );
        });
    },
    getEquipe(id) {
      const self = this;
      let api = self.$store.state.api + "equipe";
      self.$loading(true);

      axios
        .post(api, { equipe_id: id })
        .then((response) => {
          self.data_equipe = response.data;
          self.$loading(false);

          if (!self.updating) {
            setTimeout(() => {
              $("#modalEquipeCenter").modal("toggle");
            }, 400);
          }
          self.updating = false;
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, "Falha ao recuperar os dados da equipe", "error");
        });
    },
    getEquipes: function () {
      const self = this;

      self.$loading(true);

      let api = self.$store.state.api + "dashboard-frota";

      axios
        .post(api, {
          filter: {
            posicao: self.selectedMenu,
            others: self.filters,
          },
        })
        .then((response) => {
          self.equipes = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getEquipesEmbarque: function () {
      const self = this;
      let api = self.$store.state.api + "equipes?paginated=false&status=1";

      self.equipes = [];

      axios
        .get(api)
        .then((response) => {
          self.equipes_embarque = response.data.data;

          // self.getEmbarqueEquipe();
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getPosicoes: function () {
      const self = this;
      let api =
        self.$store.state.api +
        "posicao_veiculo?paginated=false&exibir_kanban=1&orderByAsc=ordem";

      axios
        .get(api)
        .then((response) => {
          self.posicoes = response.data.data;
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getTodasPosicoes: function () {
      const self = this;
      let api = self.$store.state.api + "posicao_veiculo?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.todas_posicoes = response.data.data.filter(
            (posicao) => posicao.posicao_veiculo_id !== 15
          );
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },

    getRegioes: function () {
      const self = this;
      let api = self.$store.state.api + "regioes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.regioes = response.data.data;
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getEmbarqueEquipe: function ($state) {
      const self = this;

      if (self.equipe_id == null) {
        self.$loading(false);
        return;
      }

      // self.loadedEmbarque = false;
      let api = self.$store.state.api + "embarcar_equipe/get?page=" + self.page;
      let timeOut = 0;

      if (this.page > 1) {
        timeOut = 1000;
      }

      setTimeout(() => {
        axios
          .post(api, {
            equipe_id: self.equipe_id,
            tipo_profissional_id: self.filtro_profissional,
            nome_profissional_naoatribuidos: self.searchTermNaoAtribuidos,
          })
          .then((response) => {
            var embarque = response.data;
            const nao_atribuidos = [];

            self.lastPage = embarque.nao_atribuidos.last_page;
            self.equipe_embarque.equipe_id = embarque.equipe_id;
            self.equipe_embarque.equipe_nome = embarque.equipe_nome;

            self.equipe_embarque.atribuidos = embarque.atribuidos;

            if (!self.equipe_embarque.nao_atribuidos) {
              self.equipe_embarque.nao_atribuidos = [];
            } else {
              self.equipe_embarque.nao_atribuidos.forEach((element) => {
                nao_atribuidos.push(element);
              });
            }

            embarque.nao_atribuidos.data.forEach((data, index) => {
              nao_atribuidos.push(JSON.parse(JSON.stringify(data)));
            });

            self.equipe_embarque.nao_atribuidos = nao_atribuidos;

            self.loadedEmbarque = true;

            if ($state) {
              if (self.page >= self.lastPage) {
                $state.complete();
              } else {
                $state.loaded();
              }
            }

            self.$loading(false);
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
            self.$loading(false);
          });

        this.page = this.page + 1;
      }, timeOut);
    },
    getTipoProfissional: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_profissional?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    nao_atribuidos_debounce() {
      const self = this;
      self.page = 1;

      clearTimeout(self.debounceTimeout);
      self.debounceTimeout = setTimeout(() => {
        self.equipe_embarque.nao_atribuidos = [];
        self.equipe_embarque.atribuidos = [];
        self.$loading(true);
        self.getEmbarqueEquipe();
      }, 800); // Tempo de debounce (300 milissegundos)
    },
    salvarEquipe() {
      const self = this;
      let api = self.$store.state.api + "embarcar_equipe/save";

      axios
        .post(api, self.equipe_embarque)
        .then((response) => {
          self.$message(
            "Sucesso",
            "Embarque da equipe, salvo com sucesso!",
            "success"
          );
          self.getEquipes();
        })
        .catch((error) => {
          self.resetEquipe();
          self.$message(null, error.response.data, "error");
        });
    },
    resetEquipe: function () {
      const self = this;
      self.page = 1;
      self.equipe_embarque.nao_atribuidos = [];
      self.equipe_embarque.atribuidos = [];
      self.$loading(true);
      self.getEmbarqueEquipe();
    },
    insere(index, obj) {
      const self = this;

      self.equipe_embarque.nao_atribuidos.splice(index, 1);
      self.equipe_embarque.atribuidos.push(obj);

      self.searchTermAtribuidos = "Carregando...";
      self.searchTerm = "Carregando...";
      setTimeout(function () {
        self.searchTermAtribuidos = "";
        self.searchTerm = "";
      }, 600);

      self.salvarEquipe();
    },
    remove(index, obj) {
      const self = this;

      self.equipe_embarque.atribuidos.splice(index, 1);
      self.equipe_embarque.nao_atribuidos.push(obj);

      self.searchTermAtribuidos = "Carregando...";
      self.searchTerm = "Carregando...";
      setTimeout(function () {
        self.searchTermAtribuidos = "";
        self.searchTerm = "";
      }, 600);

      self.salvarEquipe();
    },
    setPosition(obj, position) {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/movimentar_veiculo";

      if (position.hora_posicao != "-") {
        swal(
          "Cancelado",
          "Já existe uma movimentação gravada para esta posição",
          "info"
        );
        return;
      }

      swal({
        title: "Deseja gravar a data e hora desta posição?",
        text: "O horário da movimentação será o horário atual.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi alterado!", "info");
            break;

          case "confirm":
            self.$loading(true);

            const today = moment().format("YYYY-MM-DD HH:mm:ss");

            var data = {
              veiculo: {
                envio_veiculo_id: obj.veiculo.envio_veiculo_id,
                veiculo_id: obj.veiculo.veiculo_id,
              },
              tipo: position.posicao.posicao_veiculo_id,
              data: today,
            };

            axios
              .post(api, data)
              .then((response) => {
                self.$loading(false);
                swal("OK!", "O horário foi gravado com sucesso", "success");
                self.updating = true;
                self.getEquipe(self.data_equipe.equipe_id);
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
                self.$loading(false);
              });
            break;
        }
      });
    },
    salvarVeiculo: function () {
      const self = this;
      let api = self.$store.state.api + "veiculo";

      const today = moment().format("YYYY-MM-DD HH:mm:ss");

      self.veiculo_selecionado.ultima_atualizacao_posicao = today;

      if (self.veiculo_selecionado.veiculo_id) {
        self.veiculo_selecionado._method = "PUT";
        api += "/" + self.veiculo_selecionado.veiculo_id;
      }

      axios
        .post(api, self.veiculo_selecionado)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getEquipes();
          $("#modalManutencaoFrota").modal("hide");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    autoUpdateToggle(event) {
      const self = this;

      // setTimeout(() => {
      //   if (event.value == true) {
      //     self.interval = setInterval(() => {
      //       self.getEquipes();
      //     }, 15000);
      //   } else {
      //     clearInterval(self.interval);
      //   }
      // }, 2000);
    },
  },
  mounted: function () {
    const self = this;

    self.getEquipes();
    self.getEquipesEmbarque();
    self.getPosicoes();
    self.getTipoProfissional();
    self.getTodasPosicoes();
    self.getRegioes();
  },
  beforeDestroy() {
    const self = this;
    self.auto_update = false;
    clearInterval(self.interval);
  },
  components: {
    vSelect,
    InfiniteLoading,
  },
};
</script>
<style scoped>
/* .card {
  margin-bottom: 1.5rem;
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: 0px 2px 4px #999;
}
.card-header-patio {
  padding: 5px 10px;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 4px solid #28a745;
} */
.button-tripulante {
  border: solid #bebebe 1px;
  border-radius: 3px;
  padding: 3px;
  cursor: pointer;
  width: 75% !important;
}
.block {
  background-color: white;
  border-radius: 3px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  color: black;
}
.block-title {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
}
.block-title-active {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
  animation: animate 1.5s linear infinite;
}
.border-block {
  border: 1px #15488a solid;
  border-radius: 3px;
  margin: 7px;
}
@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.modal-xl {
  max-width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
}
</style>