import Crud from '../views/Rih/Table'
import Crud1 from '../views/Rih/SemVinculo'

let routes = {
    path: '/rihs',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/rihs',
            name: 'list-rihs',
            component: Crud,

            meta: {
                pageTitle: 'Lista de RIHs',
                breadcrumb: [
                    {
                        text: 'RIHs Vinculados',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/rihs-sem-vinculo',
            name: 'list-sem-vinculo-rihs',
            component: Crud1,

            meta: {
                pageTitle: 'Lista de RIHs não vinculadas',
                breadcrumb: [
                    {
                        text: 'RIHs Não vinculados',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
